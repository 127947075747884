<template>
    <div >
        <router-view></router-view>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
      };
    },
    created() {
    },
    methods:{
    },
    beforeDestroy(){
    }
  };
  </script>
  
  <style lang="scss" scoped>

  </style>